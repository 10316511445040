import {
  Box,
  TextField,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

export default function Filters({
  selectOptions,
  handleSubmit,
  filters,
  handleChange,
  handleReset,
  handleToggle,
}) {
  const fields = [
    {
      type: "text",
      label: "First Name",
      id: "firstName",
    },
    {
      type: "text",
      label: "Last Name",
      id: "lastName",
    },
    {
      type: "text",
      label: "Company Name",
      id: "companyName",
    },
    {
      type: "text",
      label: "Email Address",
      id: "email",
    },
    {
      type: "text",
      label: "City",
      id: "city",
    },
    {
      type: "select",
      label: "Country",
      id: "countries",
      options: selectOptions.countries,
    },
    {
      type: "select",
      label: "Areas Of Law",
      id: "areasOfLaw",
      options: selectOptions.areasOfLaw,
      conditionalToggle: (
        <Box sx={{ paddingBottom: 1 }}>
          <span>Search for </span>
          <ToggleButtonGroup
            size="small"
            exclusive
            value={filters.areasOfLawToggle}
            sx={{ paddingLeft: 1, paddingRight: 1 }}
            onChange={(e, val) => handleToggle("law", val)}
          >
            <ToggleButton value="any" variant="contained">
              Any
            </ToggleButton>
            <ToggleButton value="all" variant="contained">
              All
            </ToggleButton>
          </ToggleButtonGroup>
          <span>of the Areas of Law.</span>
        </Box>
      ),
    },
    {
      type: "select",
      label: "Areas Of Interest",
      id: "areasOfInterest",
      options: selectOptions.areasOfInterest,
      conditionalToggle: (
        <Box sx={{ paddingBottom: 1 }}>
          <span>Search for </span>
          <ToggleButtonGroup
            size="small"
            exclusive
            value={filters.areasOfInterestToggle}
            sx={{ paddingLeft: 1, paddingRight: 1 }}
            onChange={(e, val) => handleToggle("interest", val)}
          >
            <ToggleButton value="any" variant="contained">
              Any
            </ToggleButton>
            <ToggleButton value="all" variant="contained">
              All
            </ToggleButton>
          </ToggleButtonGroup>
          <span>of the Areas of Interest.</span>
        </Box>
      ),
    },
    // {
    //   type: "toggleButtonGroup",
    //   label: false,
    //   id: "",
    //   options: selectOptions.areasOfInterest,
    // },
  ];

  return (
    <Box component="form">
      <Grid container>
        {fields.map((props) => (
          <Field {...props} filters={filters} handleChange={handleChange} />
        ))}
        <Grid size={12} sx={{ p: 1 }}>
          {/* <Button
            variant="contained"
            onClick={handleSubmit}
            startIcon={<PersonSearchIcon />}
          >
            Search
          </Button> */}
          <Button
            onClick={handleReset}
            variant="contained"
            startIcon={<CancelPresentationIcon />}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function Field({
  type,
  id,
  label,
  options,
  filters,
  handleChange,
  conditionalToggle,
}) {
  let result;

  switch (type) {
    case "text":
      result = (
        <TextField
          onChange={(e) => handleChange(id, e.target.value)}
          fullWidth
          value={filters[id]}
          variant="outlined"
          id={id}
          label={label}
        />
      );
      break;
    case "select":
      result = (
        <Autocomplete
          multiple
          options={options}
          value={filters[id]}
          onChange={(e, newValue) => handleChange(id, newValue)}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      );
      break;
    case "toggleButtonGroup":
      result = <ToggleButtonGroup exclusive></ToggleButtonGroup>;
      break;
    default:
      break;
  }

  return (
    <Grid size={6} key={id} sx={{ p: 1 }}>
      {result}
      {conditionalToggle && <Box sx={{ pt: 1 }}>{conditionalToggle}</Box>}
    </Grid>
  );
}
