import { Avatar, Box, Typography } from "@mui/material";
import React from "react";

export default function Lawyer({ data }) {
  const {
    imageUri,
    firstName,
    lastName,
    Languages: languages,
    areas_of_interest: areasOfInterest,
    AreasofLaw: areasOfLaw,
    emails,
    addresses,
    links,
    title,
    primaryOrganization,
  } = data;
  console.log(data);
  return (
    <Box sx={{ display: "flex" }}>
      <Avatar
        src={imageUri}
        sx={{
          width: 200,
          height: 200,
          mr: 3,
          //   boxShadow: "0 0 14px rgba(0, 0, 0, 0.5)",
        }}
      />
      <Box>
        <Typography variant="h3">{firstName + " " + lastName}</Typography>

        {title && <Typography variant="h5">{title}</Typography>}
        {emails.length > 0 && (
          <List label="Emails" items={emails} href mailto />
        )}
        {links.length > 0 && <List label="Links" items={links} href />}
        {addresses.length > 0 && (
          //   <List label="Country" items={addresses.map((a) => a.country)} />
          <Box>
            <label
              style={{
                fontWeight: "700",
                marginBottom: "10px",
                display: "block",
              }}
            >
              Addresses
            </label>
            {addresses.map(
              ({
                address1,
                address2,
                address3,
                city,
                country,
                primary,
                region,
                state,
                zip,
              }) => {
                return (
                  <Box
                    sx={{
                      border: 1,
                      p: 1,
                      borderColor: primary ? "#3d6cff" : "#000",
                      mb: 1,
                    }}
                  >
                    {primary && (
                      <Box
                        sx={{
                          fontSize: "12px",
                          textTransform: "uppercase",
                          color: "#3d6cff",
                        }}
                      >
                        Primary
                      </Box>
                    )}
                    <Typography>
                      {[address1, address2, address3].map((a) => (
                        <div>{a}</div>
                      ))}
                      {city && (
                        <div>
                          {city + (state ? ", " + state : "")}
                          {zip ? ", " + zip : ""}
                          {country ? " " + country : ""}
                        </div>
                      )}
                    </Typography>
                  </Box>
                );
              }
            )}
          </Box>
        )}
        <Box sx={{ display: "flex" }}>
          {languages.length > 0 && <List label="Languages" items={languages} />}
          {areasOfInterest.length > 0 && (
            <List label="Areas Of Interest" items={areasOfInterest} />
          )}
          {areasOfLaw.length > 0 && (
            <List label="Areas Of Law" items={areasOfLaw} />
          )}
        </Box>
      </Box>
    </Box>
  );
}

function List({ label, items, href = false, mailto = false }) {
  function getItem(l) {
    if (href) {
      return <a href={mailto ? `mailto:${l}` : l}>{l}</a>;
    } else {
      return l;
    }
  }

  return (
    <Box sx={{ mr: 3 }}>
      <label style={{ fontWeight: "700" }}>{label}</label>
      <ul>
        {items.map((l) => (
          <li>{getItem(l)}</li>
        ))}
      </ul>
    </Box>
  );
}
